@import '../core/variables.scss';

.header {
  font-size: $base-font-rem * 1.5;
  font-weight: 700;
  text-transform: uppercase;
  margin: $base-whitespace $base-whitespace * 13 $base-whitespace * 4 $base-whitespace * 1.5;
  @media screen and (min-width: $breakpoint-desktop) {
    margin: $base-whitespace * 2 0 0 $base-whitespace * 3;
  }
}

.other-page-container {
  min-height: 90vh;
}

.main__container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin-left: $base-whitespace * 5;
    margin-right: $base-whitespace * 5;
  }
}

.img-page {
  overflow: hidden;
    &__cancelPayment {
      height: 375px;
      @media screen and (min-width: $breakpoint-tablet) {
        height: 400px;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        height: 500px;
        margin-left: $base-whitespace * 6;
      }
    }
  @media screen and (min-width: $breakpoint-desktop) {
    overflow: visible;
    margin-top: $base-whitespace * 3;
  }
}

.description__container {
  display: flex;
  flex-direction: column;
  justify-content: left;
  @media screen and (min-width: $breakpoint-tablet) {
    align-items: center;
  }

  &--title {
    margin: $base-whitespace * 4 $base-whitespace $base-whitespace * 2 $base-whitespace * 1.5;
    margin-top: $base-whitespace * 4;
    font-size: $base-font-rem * 2.75;
    @media screen and (min-width: $breakpoint-tablet) {
      font-size: $base-font-rem * 3;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      line-height: 75px;
    }
  }

  &--subtitle {
    margin: 0 $base-whitespace * 2 0 $base-whitespace * 1.5;
    font-size: $base-font-rem * 1.125;
    line-height: 25px;
    @media screen and (min-width: $breakpoint-tablet) {
      font-size: $base-font-rem * 1.5;
      line-height: 30px;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      max-width: 310px;
      margin-left: $base-whitespace * 2;
    }
  }

  &--button {
    margin-left: auto;
    margin-right: auto;
    background-color: $primary-color;
    height: 68px;
    font-size: 0.875rem;
    text-transform: uppercase;
    color: white;
    border-radius: 5px;
    width: 290px;
    @media screen and (min-width: $breakpoint-tablet) {
      max-width: 320px;
    }
    &:hover {
      background-color: $primary-hover;
    }
  }
}

.aContainer {
  background-color: $primary-color;
  color: #fff;
  margin-top: $base-whitespace * 2;
  text-decoration: none;
  transition: background-color 0.3s ease;
} 
.aContainer:hover {
  background-color: $primary-lightest1;
} 