@import '../core/variables.scss';

.hiUserColor {
  color: white; 
  display: none;
  font-size: $base-font-rem * 1.25;
  font-weight: 400;
  letter-spacing: 2px;
 
  @media screen and (min-width: $breakpoint-tablet) {
    display: block;
  }
}
// /* ****************** menu **************************** */
.avatarMenu {
  margin-top: $base-whitespace * 2;
}

.avatarMenuItem {
  background-color: rgba($primary-color-lightest, 0.9);
  font-size: $base-font-rem * 0.875;
  padding: $base-whitespace * 0.25 $base-whitespace * 0.75;
}

.css-6hp17o-MuiList-root-MuiMenu-list, .css-r8u8y9 {
  background-color: rgba($primary-color-lightest, 0.9);
}

.sidebar-header {
  &__container {
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
    background-color: $primary-color;
    align-items: center;
    display: flex;
    height: $header-height;
    justify-content: space-between;
    @media screen and (min-width: $breakpoint-tablet) {
      justify-content: flex-end;
      gap: $base-whitespace * 0.75;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      gap: $base-whitespace * 0.75;
      height: calc($header-height + 0.5rem);
      justify-content: flex-end;
    }
    &--accountCircle {
      color: $white;
      height: 35px;
      width: 35px;
      margin-left: 0;
      @media screen and (min-width: $breakpoint-desktop) {
        margin-right: $base-whitespace * 0.75;
      }
    }
    &--toggle {
      cursor: pointer;
      font-size: 1.2rem;
      @media screen and (min-width: $breakpoint-desktop) {
        display: none;
      }
    }
  }
}

// -------------- NAV ------------------
.sb-nav {
  background-color: rgba($primary-color-lightest, 0.9);
  box-shadow: 1px 0 0 rgba(22, 8, 43, 0.1);
  height: 100vh;
  left: -100%;
  padding: $base-whitespace $base-whitespace $base-whitespace * 3;
  position: fixed;
  transition: 0.4s;
  top: 0;
  z-index: $z-fixed;
  @media screen and (min-width: $breakpoint-desktop) {
    left: 0;
    padding: $base-whitespace $base-whitespace * 1.5 0;
    width: 40px; /* Reduced navbar */
  }
  &__container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;
    overflow: auto;
    scrollbar-width: none; /*For mozilla*/
  }
  &::-webkit-scrollbar {
    display: none; /*For Google Chrome and others*/
  }
}

.nav__logo {
  color: $primary-font-color;
  font-weight: 600;
  margin-bottom: $base-whitespace * 2.5;
  &-name {
    height: 30px;
    margin-left: 50px;
    transition: 0.3s;
    @media screen and (min-width: $breakpoint-desktop) {
      opacity: 0;
    }
  }
}

.nav__list,
.nav__items {
  display: grid;
}

.nav__list {
  row-gap: 2.5rem;
}

.nav__items {
  row-gap: 2rem;
}

.nav__link {
  align-items: center;
  background: none;
  border: none;
  color: $primary-font-color;
  cursor: pointer;
  display: flex;
  &:hover {
    color: $primary-hover;
  }
}

.nav__icon {
  margin-right: calc($base-whitespace / 2);
}

.nav__name {
  font-size: $base-font-rem;
  font-weight: 500;
  white-space: nowrap;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.125;
    opacity: 0;
    transition: 0.3s;
  }
}

.show-menu {
  left: 0;
}

.active {
  color: $primary-color-lightest4;
}

//   /* Visible elements */
.sb-nav:hover .nav__logo-name {
  opacity: 1;
}

@media screen and (min-width: $breakpoint-desktop) {
  .sb-nav:hover {
    opacity: 1;
    width: $nav-width;
  }

  .sb-nav:hover .nav__logo-name {
    opacity: 1;
  }

  .sb-nav:hover .nav__name {
    opacity: 1;
  }
}

.sb-list {
  list-style-type: none;
}

.sb-ul {
  padding-left: 0;
}
/* --------------------------- TOKENS ---------------------- */
.css-6od3lo-MuiChip-label {
  font-weight: 500;
}

.chipTokens {
  background-color: $token-bkg-color;
  border: none;
  color: $token-color;
  font-size: 22px;
  margin-left: 20px;
  letter-spacing: 0.5px;
  &:hover {
    background-color: $token-bkg-color !important;
  }
}

.tabColor {
  color: $primary-color;
}

// Banner section:

.informationBanner {
  background-color: $light-blue;
  letter-spacing: 0.5px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $dark-blue;
  font-weight: 500;
  gap: $base-whitespace * 1.75;
  @media screen and (min-width: $breakpoint-tablet) {

    font-size: $base-font-rem * 1.125;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    height: calc($header-height + 0.5rem);
    font-size: $base-font-rem * 1.25;
  }
}

.closeIcon{
  display: flex;

}

.closeIcon:hover{
  cursor: pointer
}

.BannerTransition {
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}