@import '../core/variables';

.changelogItem  {
  box-sizing: border-box;
  color: $release-title;
  font-weight: 400;
  padding: $base-whitespace 0;
  @media screen and (min-width: $breakpoint-tablet) {
    margin-left: auto;
    margin-right: auto;
    width: 560px;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    font-size: $base-font-rem * 1.125;
    width: 700px;
  }
} 

.content {
  line-height: 1.55;
} 

.titleRelease  { 
  font-size: $base-whitespace * 1.5; 
  font-weight: 500;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    font-size: $base-whitespace * 1.75; 
  }
}

.category  {
  border-radius: 20px;
  display: inline-block;
  font-size: $base-font-rem;
  font-weight: 400; 
  line-height: 25px;
  margin-bottom: 12px;
  padding: 1px 11px;
}

.category_new {
  background-color: $success-color;
  color: #fff;
}
.category_fix {
  background-color: $wrong-color;
  color: #fff;
}
.category_release {
  background-color: $primary-color;
  color: #fff;
}
.category_improve {
  background-color: $gray-7;
  color: #fff;
}


.articleStuff  { 
  align-items: center;
  display: flex;
  margin: 30px 0 10px;
} 

.authorRelease  {
  color: $outline-input-color; //change variable name future
  font-size: $base-font-rem;
  font-weight: 400;
  line-height: 30px;  
}

.imgPlaceHolder {
  border-radius: 20px; 
  text-align: center; 
  max-width: 20px;
  margin-right: 10px;
}
