.button {
  border-radius: 22.4167px;
  border: none;
  padding: 7px 14px;
  margin: 48px 24px;
  font-size: 1rem;
  box-sizing: border-box;
}
.standard {
  background-color: $btn-standard;
}
.standard:hover {
  background-color: $btn-standard-hover;
}

.button-selector-colorReport {
  color: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 8px;
  padding: 27px 83px 27px 83px;
  width: 325px;
  margin-top: 60px;
}
.button-selector-paMethodology {
  color: $primary-color;
  border: 2px solid $primary-color;
  border-radius: 8px;
  padding: 27px 83px 27px 83px;
  width: 325px;
  margin-top: 60px;
}

.button-selector-colorReport:hover {
  background-color: $btn-selector-bkg;
  border: 2px solid $primary-color;
  color: $primary-color;
}
.button-selector-paMethodology:hover {
  background-color: $btn-selector-bkg;
  border: 2px solid $primary-color;
  color: $primary-color;
}
