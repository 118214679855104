@import '../core/variables.scss';
/*BUTTONS*/
.landingButton {
  float: right;
  padding: 0.75rem;
  text-decoration: none;
  font-weight: 400;
  border-radius: 8px;
  line-height: 1em;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: 1rem;
    padding: 0.75rem 1.5rem 0.625rem 1.5rem;
    font-size: 1.125rem;
  }
}

.secondary {
  color: $primary-color;
  text-decoration: none;
  background: none;
}

.secondary:hover {
  cursor: pointer;
  background-color: $primary-bkg-color;
}

.predominantButton {
  display: inline-block;
  margin-top: 32px;
  padding: 20px 40px 18px 40px;
  border-radius: 8px;
  font-weight: 400;
  font-size: $base-font-rem * 1.125;
  text-decoration: none;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
}

.predominantButton:hover {
  cursor: pointer;
}

.ukFlag,
.esFlag {
  border-radius: 8px;
  height: 25px;
  width: 25px;
}

.defaultLandingStyle {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  display: flex;
  justify-content: space-around;
}

.landing-header {
  padding: $base-whitespace * 0.75;
}

.containerLanding {
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  @media screen and (min-width: $breakpoint-tablet) {
    max-width: 1264px;
    width: 80%;
  }
}

.betaTag {
  float: left;
  display: flex;
  padding: 6px;
}

.betaTest {
  font-size: $base-font-rem * 0.875; 
  font-weight: 400;
  border-radius: 8px;
  height: 15px;
  line-height: 1em;
  -webkit-transition: background 0.15s ease-in-out;
  transition: background 0.15s ease-in-out;
  padding: 6px;
  color: $primary-color;
  background: $primary-bkg-color;
  @media screen and (min-width: $breakpoint-tablet) {
    font-size: $base-font-rem;
  }
 
}

.logoFlagsContainer {
  @media screen and (min-width: $breakpoint-tablet) {
    display: flex;
    flex-wrap: wrap;
    padding-top: 6px;
  }
}

.flags {
  border: none;
  background: none;
  cursor: pointer;
}

.flags:active {
  background-color: $primary-color-lightest;
}

.btnsContainer {
  height: 40px;
  width: 95%;
}

/* 1st section*/
.flexHeader {
  margin-top: $base-whitespace * 6;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
    justify-content: start;
  }
}

.initialTextMargin {
  margin-right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.predominantHeader {
  text-align: center;
  font-size: $base-font-rem * 2.25;
  line-height: 3rem;
  margin: 0;
  padding: 0;
  border: 0;
  font-weight: normal;
  color: $primary-font-color;
  margin-bottom: 24px;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 3.25;
    line-height: 3.625rem;
    text-align: left;
  }
}

.eng-header {
  padding-left: 80px;
  padding-right: 80px;
}

.finalHeaderLayout > .predominantHeader {
  text-align: center;
}

.subHeader {
  text-align: center;
  color: $subheaders;
  font-weight: normal;
  font-size: 1rem;
  line-height: 28px;
  margin: 0 0 10px;
  @media screen and (min-width: $breakpoint-desktop) {
    text-align: left;
    font-size: $base-font-rem * 1.25;
  }
}

.algorits-img {
  margin-top: $base-whitespace * 6;
  height: 300px;
  width: 700px;
  @media screen and (min-width: $breakpoint-tablet) {
    margin-top: 0;
    height: 450px;
    width: 450px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    height: 450px;
    width: 450px;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    height: 500px;
    width: 500px;
  }
}
/* 2nd section*/
.flexSubHeader {
  margin-top: $base-whitespace * 8;
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-desktop) {
    flex-direction: row;
    gap: $base-whitespace * 3;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.item1IconColor {
  color: $success-color;
}

.item2IconColor {
  color: $wrong-color;
  margin-top: $base-whitespace * 2;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-top: 0;
  }
}

.item3IconColor {
  color: $primary-color;
  margin-top: $base-whitespace * 2;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-top: 0;
  }
}

.smallerHeaders {
  padding: 0;
  border: 0;
  font-size: $base-font-rem * 1.5;
  font-weight: normal;
  color: $primary-font-color;
  margin-bottom: $base-whitespace;
  margin-top: $base-whitespace;
}

.itemExplanation {
  max-width: 28ch;
  @media screen and (min-width: $breakpoint-desktop) {
    text-align: center;
  }
}
/*3rd section*/
.rectangle {
  padding-left: calc($base-whitespace / 4);
  padding-right: calc($base-whitespace / 4);
  margin-top: $base-whitespace * 8;
  border-radius: 8px;
  background: $primary-color;
  opacity: 0.85;
  @media screen and (min-width: $breakpoint-desktop) {
    width: 650px;
    height: 500px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    width: 1240px;
    height: 320px;
  }
}

.headerHowDoesWork {
  text-align: center;
  padding: $base-whitespace * 1.5;
  margin-bottom: calc($base-whitespace / 2);
  color: white;
  font-weight: 400;
  font-size: $base-font-rem * 1.5;
}

.displayHowToUse {
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding-right: 5px;
  padding-left: 5px;
  @media screen and (min-width: $breakpoint-tablet) {
    padding: 24px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    align-items: start;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    justify-items: center;
    padding: 0;
    position: relative;
  }
}

.howWorksColorIcon {
  color: $white;
}

.DataObjectRoundedIcon {
  grid-row-start: 2;
  @media screen and (min-width: $breakpoint-desktop) {
    grid-row-start: 2;
  }
}

.OnlinePredictionRoundedIcon {
  grid-row-start: 4;
  @media screen and (min-width: $breakpoint-desktop) {
    grid-row-start: 2;
  }
}

.explanationHowDoesWork {
  margin-top: 12px;
  text-align: center;
  max-width: 30ch;
  color: $white;
  font-weight: 400;
  font-size: $base-font-rem * 1.25;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-top: -10px;
  }
}
/*4rd section*/
.frecuentAskedQFmt {
  margin-top: $base-whitespace * 8;
  display: flex;
  flex-direction: column;
  gap: $base-whitespace * 5;
  align-items: center;
}

.headerAskedQSpacing {
  padding: $base-whitespace * 0.75;
  display: flex;
  flex-direction: column;
  gap: $base-whitespace * 1.5;
  @media screen and (min-width: $breakpoint-desktop) {
    gap: $base-whitespace;
  }
}

.mainHeaderFreqAskedQ {
  margin: 0;
  font-size: $base-font-rem * 1.875;
  font-weight: 400;
  line-height: 1;
}

.subHeaderFreqAskedQ {
  font-weight: 400;
  font-size: $base-font-rem * 1.125;
  line-height: $base-whitespace * 2;
  color: $subheaders;
}

.reachUsFmt {
  color: $primary-color;
  font-weight: 400;
  font-size: $base-font-rem;
  line-height: 1.625rem;
  -moz-column-gap: 0.75rem;
  column-gap: normal;
  text-decoration: none;
}

.reachUsFmt:hover {
  transition-duration: 0.2s;
}

.questionsLayout {
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  flex-direction: column;
  text-align: justify;
  text-justify: inter-word;
  @media screen and (min-width: $breakpoint-tablet) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 2rem;
    row-gap: 1rem;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 6rem;
    row-gap: 2rem;
    padding-left: $base-whitespace * 2;
    padding-right: $base-whitespace * 2;
  }
}

.questionHeader {
  font-size: $base-font-rem * 1.125;
  font-weight: 600;
  line-height: 1.75rem;
  text-align: left;
  color: $primary-font-color;
}

.responseBody {
  font-weight: 400;
  padding-bottom: $base-whitespace;
  line-height: 1.625rem;
  @media screen and (min-width: $breakpoint-desktop) {
    min-width: 60ch;
  }
}
/*final section*/
.finalHeaderSpacing {
  padding: 128px 0px 240px 0px;
  display: flex;
  justify-content: center;
}

.finalHeaderSpacing .containerLanding {
  display: block;
}

.finalHeaderLayout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.finalHeaderSpacing p.defaultLandingStyle {
  padding-right: 0;
}

/* logIn & contacta footer*/
.logIn {
  font-size: $base-font-rem;
}
