@import '../core/variables.scss';
//TODO: remove whitespace after html tag
.logInBox {
  margin-left: calc($base-whitespace / 2);
  margin-right: calc($base-whitespace / 2);
  margin-top: $base-whitespace * 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (min-width: $breakpoint-tablet) {
    margin-top: $base-whitespace * 6;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    margin: $base-whitespace * 6 auto auto auto;
    width: 420px;
    margin-top: $base-whitespace * 1.25;
  }
}

.logoDiv {
  margin-bottom: $base-whitespace;
}

.IconLogInColor {
  color: $primary-color;
}

.logInHeader, .signInHeader {
  font-size: $base-font-rem * 1.5;
  font-weight: 400;
  color: $primary-font-color;
  letter-spacing: 1px;
}

.form {
  width: 90%;
  @media screen and (min-width: $breakpoint-tablet) {
    display: flex;
    flex-direction: column;
    width: 60%;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    width: 100%;
  }
}

.redirectLogin {
  color: $primary-color;
  text-decoration: none;
}

.redirectLogin:hover {
  text-decoration: underline;
}

.restorePwdComment {
  max-width: 35ch;
  line-height: 1.7;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media screen and (min-width: $breakpoint-tablet) {
    max-width: 50ch;
    font-size: $base-font-rem * 1.25;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    max-width: 80ch;
  }
}

.restorePassHeader {
  letter-spacing: 1px;
  color: $primary-font-color;
}

.haveAcc {
  width: 50%;
  @media screen and (min-width: $breakpoint-tablet) {
    width: 60%;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    width: 100%;
  }
}
/*ConfirmationForgotPass*/
.confirmationRestoredPwd {
  font-size: $base-whitespace * 1.5;
  line-height: 1.7;
  max-width: 40ch;
  text-align: center;
  margin-top: $base-whitespace * 1.5;
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: $base-whitespace * 1.875;
  }
}

.securityTipHead {
  font-size: $base-font-rem * 1.125;
  font-weight: 500;
  margin-top: $base-whitespace * 2;
  text-align: left;
}

.securityTip {
  font-size: $base-font-rem * 1.125;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.5px;
  text-align: justify;
  text-justify: inter-word;
}
