/*FONT SIZE SYSTEM (px) 10 / 12 / 14 / 16 / 18 (1.125rem) / 20 (1.25) / 24 (1.5) / 30 (1.875) / 36 (2.25) / 44 (2.75) / 52 (3.25) / 62 (3.875) / 74 (4.625) / 86 (5.375) / 98 (6.125)*/
//WHITESPACING SYSTEM (px): 2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

$breakpoint-tablet: 768px;
$breakpoint-desktop: 1280px;
$breakpoint-largeDesktop: 1800px;

//font
$primary-font: 'Roboto', sans-serif;

//sizes
$base-font-rem: 1rem;
$base-whitespace: 16px;

$header-height: 3.5rem;
$nav-width: 219px;
$z-fixed: 100;

//colors
$white: white;
$background-color: #fafafa;
$primary-color-lightest: #f3f2f7;
$primary-color-lightest4: #a695db;
$primary-lightest1: #845ef7; //VIOLET 5
$primary-lightest2: #7950f2; //VIOLET 6
$primary-color: #7048e8; //VIOLET 7
$primary-hover: #5f3dc4; //VIOLET 9
$primary-bkg-color: #f1f3f5; //GRAY 1
$primary-font-color: #212529; // GRAY 9
$subheaders: #746e8c;
$success-color: #087f5b;
$wrong-color: #a61e4d;
$delete-color: #fa5252;
$delete-color-hover: #c92a2a;
$gray-7: #495057;
$btn-standard: #e9ecef;
$btn-standard-hover: #ced4da;
$btn-selector-bkg: #f3f0ff;
$token-bkg-color: #ffec99;
$token-color: #f08c00; //yelow 8
$outline-input-color: #adb5bd; //GRAY 5
$release-title: #495057; //GRAY 7
$switch-color: #9775fa; //VIOLET 4
$light-blue: #E1F3FB;
$dark-blue: #022398;