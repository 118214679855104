@import '../core/variables.scss';

.IconLogInColor {
  &-savedSimulations {
    color: $primary-color;
    margin-top: 0;
  }
}

.savedAlgorithmsDisplay {
  display: grid;
  grid-template-columns: 20% 80%;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: $base-whitespace * 5.5;
  }
}

.listUl {
  grid-column-start: 1;
}
.savedSim {
  grid-column-start: 2;
}

.savedSimulations-par {
  color: $gray-7;
  display: block;
  font-size: $base-font-rem * 0.875;
  font-weight: normal;
  margin-left: auto;
  margin-right: auto;
  margin-top: $base-whitespace;
  text-align: center;
  @media screen and (min-width: $breakpoint-tablet) {
    font-size: $base-font-rem * 1.125;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}