@import '../core/variables.scss';
//pasar esta clase a _App y a donde haga falta ese margin-left
.container-margin {
  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: $base-whitespace * 5.5 !important;
  }
}
.css-1q2h7u5.Mui-selected {
  color: $primary-color !important;
}
.css-ttwr4n {
  background-color: $primary-color !important;
}

// /* div de los iconos de cada icono de las explicaciones de los algoritmos */
.MuiBottomNavigation-root, .css-16lloyr-MuiBottomNavigation-root {
  grid-row-start: 1;
  background-color: $background-color;
  margin-top: $base-whitespace;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: $base-whitespace * 1.5;
  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(5, 1fr);
    margin: $base-whitespace $base-whitespace * 1.5 0 $base-whitespace * 1.5;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(9, 1fr);
    margin-right: $base-whitespace * 10;
    margin-left: $base-whitespace * 5;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-left: 0;
  }
}

.glossaryIcons {
  height: 50px;
  width: auto;
  @media screen and (min-width: $breakpoint-tablet) {
    height: 60px;
  }
}

//.css-1bjk3jo-MuiButtonBase-root-MuiBottomNavigationAction-root.Mui-selected {
.css-sxw2zh.Mui-selected{
  color: $primary-color !important;
  //JUST IN CASE SE DESCOLOCA TODO  display: flex;
  // flex-direction: column;
  // align-items: center;
}

.allDefinitionLayout {
  @media screen and (min-width: $breakpoint-tablet) {
    margin: $base-whitespace * 11 $base-whitespace 0 $base-whitespace;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    margin: $base-whitespace * 5 $base-whitespace * 9 0 $base-whitespace * 6;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-right: $base-whitespace;
  }
  &__inputs-explanation {
    margin-top: $base-whitespace * 30;
    @media screen and (min-width: $breakpoint-tablet) {
      margin-top: $base-whitespace * 18;
    }
    @media screen and (min-width: $breakpoint-desktop) {
      margin-top: $base-whitespace * 13;
    }
    &--title {
      margin-top: $base-whitespace * 6;
      font-size: $base-font-rem * 1.875;
      font-weight: 400;
      margin: 0;
    }
    &--paragraph {
      font-size: $base-font-rem * 1.125;
      text-align: justify;
      text-justify: inter-word;
      line-height: 1.7;
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.25;
        max-width: 90%;
      }
      @media screen and (min-width: $breakpoint-largeDesktop) {
      }
    }
  }
  &__algorithms-explanation {
    @media screen and (min-width: $breakpoint-desktop) {
      display: grid;
      grid-template-columns: 20% 80%;
      align-items: baseline;
    }
    @media screen and (min-width: $breakpoint-largeDesktop) {
      max-width: 90%;
    }
    &--headers {
      margin-top: $base-whitespace * 18;
      font-size: $base-font-rem * 1.875;
      font-weight: 400;
      @media screen and (min-width: $breakpoint-tablet) {
        margin-top: $base-whitespace * 12;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        margin-top: $base-whitespace * 6;
      }
    }
    &--introductionAlgorithm {
      text-align: justify;
      text-justify: inter-word;
      font-size: $base-font-rem * 1.125;
      line-height: 1.7;
      @media screen and (min-width: $breakpoint-tablet) {
        max-width: 65ch;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.25;
        max-width: 90%;
      }
      @media screen and (min-width: $breakpoint-largeDesktop) {
        margin-right: $base-whitespace;
      }
    }
    &--title {
      margin-top: $base-whitespace * 6;
      font-size: $base-font-rem * 1.25;
      font-weight: 400;
      margin: 0;
    }
    &--paragraph {
      font-size: $base-font-rem * 1.125;
      text-align: justify;
      text-justify: inter-word;
      line-height: 1.7;
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.25;
        max-width: 90%;
      }
    }
  }
}

.no-definitions {
  margin-top: $base-whitespace;
  font-size: $base-font-rem * 1.125;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.7;
  @media screen and (min-width: $breakpoint-tablet) {
    max-width: 65ch;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}

.videosDisplay{
  display: grid;
  margin-top: $base-whitespace * 3;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: $base-whitespace * 4;
  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $base-whitespace * 4;
    grid-row-gap: $base-whitespace * 4;
    margin-left: $base-whitespace;
  }
}

.videoFrame{
  max-height: 315px;
  max-width: 560px;
  @media screen and (min-width: $breakpoint-desktop) {
    height: 520px;
    width: 500px;
  }
}