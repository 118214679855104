@import '../core/variables.scss';

.algorithm-card-size {
  display: grid;
  margin-top: $base-whitespace * 3;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-row-gap: $base-whitespace * 4;
  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: $base-whitespace * 2;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: $base-whitespace * 4;
    grid-row-gap: $base-whitespace * 4;
    margin-left: $base-whitespace * 5.5;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.algorithm-cards {
  padding: 10px;
  border: 1px solid $primary-bkg-color;
  box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  max-width: 343px;
  &__card-name {
    padding: 12px;
    display: flex;
    align-items: center;
    &--algorithmName {
      font-size: $base-font-rem * 1.875;
      font-weight: 500;
      margin: 0 0 4px $base-whitespace;
      @media screen and (min-width: $breakpoint-tablet) {
        font-size: $base-font-rem * 1.75;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.875;
      }
    }
  }
  &__algorithmDescription {
    max-height: 40px;
    font-size: $base-font-rem * 1.125;
    font-weight: 300;
    margin-left: 12px;
    margin-right: 12px;
  }
  &__predominantButton {
    background-color: $primary-lightest2;
    border: 1px solid #e5dbff;
    border-radius: 8px;
    box-sizing: border-box;
    color: $white;
    cursor: pointer;
    display: inline-block;
    font-size: $base-font-rem * 1.125;
    font-weight: 400;
    margin: 32px 0 24px 12px;
    padding: 20px 40px 18px 40px;
    transition: background 0.15s ease-in-out;
    text-decoration: none;
    -webkit-transition: background 0.15s ease-in-out;
    & button {
      background: none;
      border: none;
      color: inherit;
      font-size: inherit;
      font-weight: inherit;
      padding: 0;
    }
  }
}

.iconTest {
  color: $primary-color;
}

.tokensAlgorithms {
  color: $token-color;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  justify-content: flex-end;
}
