@import '../core/variables.scss';
/* ************** Algorithms outputs ********************** */
.fundamentals-table {
    box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    width: 680px;
    &__thead {
        background-color: rgba($primary-color-lightest, 0.6);
        &-trow-theader {
            font-size: $base-font-rem * 0.875;
            padding: 10px;
            @media screen and (min-width: $breakpoint-tablet) {
                font-size: $base-font-rem;
            }
            @media screen and (min-width: $breakpoint-desktop) {
              font-size: $base-font-rem * 1.25;
            }
          }
      }
      &__tbody {
        &-trow {
          cursor: pointer;
          &-tdata {
            border-bottom: 1px solid $primary-color-lightest;
            border-top: 1px solid $primary-color-lightest;
            font-size: $base-font-rem * 0.875;
            padding: 8px;
            &:not(:first-child) {
                text-align: center;
            }
            @media screen and (min-width: $breakpoint-tablet) {
              font-size: $base-font-rem;
              padding: 10px;
            }
            @media screen and (min-width: $breakpoint-largeDesktop) {
              font-size: $base-font-rem * 1.125;
            }
          }
        }
      }
  }

.fundamentals-additional-data:first-child {
  text-align: center;
  max-width: 630px;
  @media screen and (min-width: $breakpoint-desktop) {
    margin-left: $base-whitespace ;
    margin-right: $base-whitespace;
  }
}

hr {
    margin-top: $base-whitespace * 2;
}
/* ************** Analysts outputs ********************** */
.analysts-output {
  max-width: 300px;
  &__headers {
    font-size: $base-font-rem * 1.5;
    font-weight: 400;
    margin-left: $base-whitespace;
  }
  &__descriptions {
      font-size: $base-font-rem ;
      margin-left: $base-whitespace;
      line-height: 1.7;
  }
}

.displayAnalystOutput {
  display: grid;
  grid-template-columns: auto auto;
  margin-left: $base-whitespace * 2;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    grid-template-columns: auto auto auto;
  }
}
/* ************** Stock metrics outputs ********************** */
.pmStocks-container {
  max-height: 500px;
  overflow: auto;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    max-height: 700px;
  }
}

.pmStocks-table__thead-trow > .pmStocks-table__thead-trow-theader:first-child, 
.pmStocks-table__tbody-trow > .pmStocks-table__tbody-trow-tdata:first-child {
  background-color: rgba($primary-color-lightest, 1);
  box-shadow: 4px 4px 10px rgba(0,0,0,.1);
  position: sticky;
  left: 0;
}

tr th {
  background-color: rgba($primary-color-lightest, 1);
}

tr td {
  background: $background-color;
}

.pmStocks-table{
  margin-left: auto;
  margin-right: auto;
  box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  &__thead {
      background-color: rgba($primary-color-lightest, 1);
      &-trow {
        box-shadow: 4px 4px 10px rgba(0,0,0,.1);
        position: sticky;
        top: 0;
        z-index: 1;
      }
      &-trow-theader {
          font-size: $base-font-rem * 0.875;
          padding: 10px;
          @media screen and (min-width: $breakpoint-tablet) {
              font-size: $base-font-rem;
          }
          @media screen and (min-width: $breakpoint-desktop) {
            font-size: $base-font-rem * 1.25;
          }
        }
    }
    &__tbody {
      &-trow {
        &-tdata {
          border-bottom: 1px solid $primary-color-lightest;
          border-top: 1px solid $primary-color-lightest;
          font-size: $base-font-rem * 0.875;
          padding: 8px;
          &:not(:first-child) {
              text-align: center;
          }
          @media screen and (min-width: $breakpoint-tablet) {
            font-size: $base-font-rem;
            padding: 10px;
          }
          @media screen and (min-width: $breakpoint-largeDesktop) {
            font-size: $base-font-rem * 1.125;
          }
        }
      }
    }
}

.oneTableOuput {
  margin: $base-whitespace * 3;
}
/* ************** Portofolio optimizer outputs ********************** */
.opt-table {
  box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
  &__thead {
      background-color: rgba($primary-color-lightest, 0.6);
      &-trow-theader {
          font-size: $base-font-rem * 0.875;
          padding: 10px;
          @media screen and (min-width: $breakpoint-tablet) {
              font-size: $base-font-rem;
          }
          @media screen and (min-width: $breakpoint-desktop) {
            font-size: $base-font-rem * 1.25;
          }
        }
    }
    &__tbody {
      &-trow {
        &-tdata {
          border-bottom: 1px solid $primary-color-lightest;
          border-top: 1px solid $primary-color-lightest;
          font-size: $base-font-rem * 0.875;
          padding: 8px;
          &:not(:first-child) {
              text-align: center;
          }
          @media screen and (min-width: $breakpoint-tablet) {
            font-size: $base-font-rem;
            padding: 10px;
          }
          @media screen and (min-width: $breakpoint-largeDesktop) {
            font-size: $base-font-rem * 1.125;
          }
        }
      }
    }
}

.opt-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.sepOptOutputTxt {
  line-height: 1.75;
  margin-bottom: $base-whitespace * 2;
  margin-left: auto;
  margin-right: auto;
  margin-top: $base-whitespace * 2;
  max-width: 75ch;
}

.opt-chart {
  margin-left: auto;
  margin-right: auto;
}

.prev-btn, .nxt-btn {
background: none;
border: none;
color: $primary-color;
cursor: pointer;
padding: 0;
}

/* ************** var outputs ********************** */
.cashInput {
  display: grid;
  justify-items: center;
  grid-row-gap: 20px;
  margin-bottom: $base-whitespace * 3;
}

.varOuputHeader {
  grid-column-start: span 3;
  &__stn {
    font-size: $base-font-rem * 1.5;
    grid-column-start: 1;
    grid-column-end: 2;
    @media screen and (min-width: $breakpoint-largeDesktop) {
      grid-column-end: 3;
    }
  }
}
/* ************** reports outputs ********************** */
.twobuttonDistribution {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  grid-gap: 40px;
}
/* ************** trend analyzer outputs ********************** */
.displaySTNOutput {
  display: grid;
  grid-template-columns: 1fr;
  margin-left: $base-whitespace * 2;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px
  }
}

.headers {
  font-size: $base-font-rem * 1.125;
  font-weight: 400;
}
/* ************** black litterman outputs ********************** */
.bl-table {
  margin-left: $base-whitespace * 0.75;
  background-color: $white;
  @media screen and (min-width: $breakpoint-desktop) {
    box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  }
  &__thead {
    background-color: rgba($primary-color-lightest, 0.6);
    &-trow-theader {
      font-size: $base-font-rem * 0.875;
      padding: 8px 0px; //dsfdsfd
      @media screen and (min-width: $breakpoint-tablet) {
        font-size: $base-font-rem;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.25;
      }
    }
  }
  &__tbody {
    &-trow {
      &-tdata {
        border-bottom: 1px solid $primary-color-lightest;
        border-top: 1px solid $primary-color-lightest;
        font-size: $base-font-rem * 0.875;
        padding: 8px;
        text-align: center;
        @media screen and (min-width: $breakpoint-tablet) {
          font-size: $base-font-rem;
          padding: 10px;
        }
        &:last-child {
          font-weight: 500;
        }
        @media screen and (min-width: $breakpoint-desktop) {
          font-size: $base-font-rem * 1.25;
        }
      }
    }
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-left: auto;
    margin-right: auto;
  }
}
/* ************** p.a outputs ********************** */
.pa-container {
  max-height: 500px;
  overflow: auto;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    max-height: 700px;
  }
}

.pa-table__thead-trow > .pa-table__thead-trow-theader:first-child, 
.pa-table__tbody-trow > .pa-table__tbody-trow-tdata:first-child {
  background-color: rgba($primary-color-lightest, 1);
  box-shadow: 4px 4px 10px rgba(0,0,0,.1);
  position: sticky;
  left: 0;
}

.pa-table{
  box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  &__thead {
      background-color: rgba($primary-color-lightest, 1);
      &-trow {
        box-shadow: 4px 4px 10px rgba(0,0,0,.1);
        position: sticky;
        top: 0;
        z-index: 1;
      }
      &-trow-theader {
          font-size: $base-font-rem * 0.875;
          padding: 10px;
          @media screen and (min-width: $breakpoint-tablet) {
              font-size: $base-font-rem;
          }
          @media screen and (min-width: $breakpoint-desktop) {
            font-size: $base-font-rem * 1.25;
          }
        }
    }
    &__tbody {
      &-trow {
        &-tdata {
          border-bottom: 1px solid $primary-color-lightest;
          border-top: 1px solid $primary-color-lightest;
          font-size: $base-font-rem * 0.875;
          padding: 8px;
          &:not(:first-child) {
              text-align: center;
          }
          @media screen and (min-width: $breakpoint-tablet) {
            font-size: $base-font-rem;
            padding: 10px;
          }
          @media screen and (min-width: $breakpoint-largeDesktop) {
            font-size: $base-font-rem * 1.125;
          }
        }
      }
    }
}
.menu {
  position: relative;
  display: inline-block;
}

.menu-button {
  background-color: rgba($primary-color-lightest, 1);
  border: none;
  cursor: pointer;
}

.menu-items {
  background-color: $primary-bkg-color;
  border: 1px solid $outline-input-color;
  display: none;
  height: 170px;
  left: 0;
  list-style-type: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 100%;
  width: 160px;
}

.menu-items {
  &__li {
    display: flex;
    margin-left: 15px;
  }
}

input[type='checkbox'] {
  accent-color: $primary-color;
}

.menu-items li a {
  color: #333333;
  text-decoration: none;
}

.menu.open .menu-items {
  display: block;
}

.menuOptionsPa {
  color: black;
  margin-left: 10px;
  margin-top: 15px;
  margin-bottom: 15px;
}

.defaultPaFontSize {
  @media screen and (min-width: $breakpoint-largeDesktop) {
    font-size: 18px;
  }
}

.negativeContribution {
  color: $wrong-color;
  font-weight: 700;
  font-size: $base-font-rem * 1.125;
}
.positiveContribution {
  color: $success-color;
  font-weight: 700;
  font-size: $base-font-rem * 1.125;
}

.headerPA {
  font-size: $base-font-rem * 2;
  color: $primary-font-color;
}

.subHeadersPA {
  font-size: $base-font-rem * 1.5;
  font-weight: 500;
  color: $gray-7;
}

.conclusionsPA {
  color: $gray-7;
  font-weight: 400;
  margin-bottom: $base-whitespace * 1.5;
  line-height: 1.75;
}

.bulletPointsPA {
  color: $gray-7;
  font-weight: 400;
  line-height: 1.75;
}

.handmadeNotePA {
  font-family: 'Indie Flower';
  font-size: $base-font-rem * 1.5;
  font-weight: 700;
  color: #be5f4b;
  margin-left: 600px;
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-left: 1080px;
  }
}