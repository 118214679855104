@import '../core/variables.scss';

.allReleasesLayout {
  margin-top: $base-whitespace * 4;
  @media screen and (min-width: $breakpoint-tablet) {
    display: grid;
    justify-content: center;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    margin-top: $base-whitespace * 1.5;
  }
}

.settingsHeader {
  font-size: $base-font-rem * 1.875;
  margin-bottom: $base-whitespace * 3;
  font-weight: 400;
  color: $primary-color;
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: $base-font-rem * 2.25;
  }
  @media screen and (max-width: $breakpoint-desktop) {
    margin-bottom: $base-whitespace * 1.5;
  }
}

.profileLabel {
  color: $primary-color;
  font-size: $base-font-rem * 1.25;
  margin: ($base-whitespace * 1.5) 0;
  font-weight: 400;
  @media screen and (max-width: $breakpoint-tablet) {
    font-size: $base-font-rem * 1.5;
  }
}

.posbut {
  display: flex;
  justify-content: flex-end;
  margin-bottom: $base-whitespace * 4;
}

.saveSettngsButton {
  margin-top: $base-whitespace * 3;
  margin-bottom: $base-whitespace * 1.5;
}

.buttonsLayout {
  display: grid;
  justify-content: end;
}

.deleteButton {
  background: $delete-color;
  color: $white;
}
.deleteButton:hover {
  background: $delete-color-hover;
  cursor: pointer;
}

/* POP UP DELETE ACCOUNT*/
.descriptionDelete {
  color: $gray-7;
  font-weight: 400;
  font-size: $base-font-rem * 1.125;
}
