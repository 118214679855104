@import './core/variables.scss';
@import './components/buttons.scss';

* {
  font-family: $primary-font;
  font-weight: 300;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

body {
  background-color: $background-color;
  display: flex;
  flex-direction: column;
  margin: 0;
  position: relative;
}

/*LOGO*/
.algoUiIcon {
  padding: 6px;
  width: 65px;
  @media screen and (min-width: $breakpoint-tablet) {
    padding: 2px 12px 6px 12px;
  }
}
/*BTN PRIMARY*/
.primary {
  cursor: pointer;
  background: $primary-color;
  color: #fff;
}

.primary:hover {
  background: $primary-hover;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $subheaders;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $gray-7;
}
/*copyright*/
.copyright-text {
  color: rgba(0, 0, 0, 0.87);
  font-weight: normal;
  text-transform: capitalize;
}

.copyright-container {
  display: flex;
  justify-content: center;
  justify-self: flex-end;
}

//borrar si queda feo o ajustar, el codigo es principalmente para sideebar
::before,
::after {
  box-sizing: border-box;
}

h3 {
  margin: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}
/*MUI --Borrar cuando prescindamos de MUI--*/
.MuiButton-root {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
.MuiSvgIcon-root {
  font-size: 2rem;
}
.MuiInputBase-input {
  font-family: 'Roboto', sans-serif;
  font-size: $base-font-rem;
  width: 300px;
}
/* pagination input selection registries */
.MuiTablePagination-select {
  width: 0px;
  font-size: 0.875rem;
}
/* calendar days */
.MuiPickersDay-root {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
}
/* calendar month and year */
.PrivatePickersFadeTransitionGroup-root {
  font-size: 24px;
}
/* days of the week: */
.MuiTypography-caption {
  font-size: 24px;
  display: flex;
  padding: 0;
  font-weight: 400;
}
/* label borders */
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary-color !important;
}
/* SideBar */
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper, .css-1l8j5k8 {
  background-color: #fafafa;
}
.MuiFormLabel-root {
  font-family: 'Roboto', sans-serif;
  font-size: $base-font-rem * 1.125;
}
.MuiFormLabel-root.Mui-focused {
  color: $primary-color !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: $primary-color !important;
}
/* tabs */
.MuiTab-root {
  color: $primary-color;
  font-size: 20px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: $primary-color;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: $primary-color;
}
/* checkbox */
.css-8l0d08-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-8l0d08-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: $primary-color;
}
/* switch color */
.css-1nr2wod.Mui-checked {
  color: $switch-color;
}
.css-1nr2wod.Mui-checked + .MuiSwitch-track{
  background-color: $switch-color;
}
