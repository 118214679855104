@import '../core/variables.scss';

.inputDisplay {
    display: grid;
    grid-template-columns: 30% 70%;
    @media screen and (min-width: $breakpoint-desktop) {
        margin-left: $base-whitespace * 4.5;
        grid-template-columns: 20% 80%;
    }
}

.listUl {
    padding-top: 0;
    width: 100%;
}

/*DATE PICKER*/
.css-15fehp8-MuiTypography-root-PrivatePickersMonth-root.Mui-selected, .css-3eghsz-PrivatePickersYear-button.Mui-selected {
    background-color: $primary-color !important;
}

.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root.Mui-selected {
    background-color: $primary-color !important;
}
/* BENCHMARK */
.multiplebuttonDistribution {
    display: grid;
    grid-template-columns: auto auto auto;
    justify-items: center;
    grid-gap: $base-whitespace * 4;
}

.button-selector-benchmark {
    color: $primary-color;
    border-radius: 8px;
    border: 2px solid $primary-color;
    padding: 27px 83px 27px 83px;
    width: 220px;
    margin:0;
    &:hover {
        background-color: $primary-hover;
        border: 2px solid $primary-color;
        color: $white;
    }
    @media screen and (min-width: $breakpoint-largeDesktop) {
        width: 250px;
    }
}
/* ACTIVO RIESGO LIBRE */
.buttonSelect {
    font-size: $base-font-rem;
    font-weight: 400;
  }

.button-selector-rfRates {
    color: $primary-color;
    border-radius: 8px;
    border: 2px solid $primary-color;
    width: 200px;
    margin: 0;
    @media screen and (min-width: $breakpoint-largeDesktop) {
        width: 250px;
        padding: 27px 33px 27px 33px;
    }
    &:hover {
        background-color: $primary-hover;
        border: 2px solid $primary-color;
        color: $white;
    }
}

.button-selector-inputForecast {
    color: $primary-color;
    border: 2px solid $primary-color;
    border-radius: 8px;
    padding: 27px 33px 27px 33px;
    width: 200px;
    margin-top: $base-whitespace * 3;
    &:hover {
        background-color: $btn-selector-bkg;
        border: 2px solid $primary-color;
        color: $primary-color;
    }
}

.markSelected {
    background-color: $primary-color;
    color: $white;
}
/* ************** black litterman inputs ********************** */
.lineFormatViews {
    margin-top: $base-whitespace;
    margin-left: $base-whitespace;
    display: flex;
    gap: $base-whitespace * 0.75;
  }
  
  .lineFormatViews > div > div{
    width: 250px;
  }
  
  .addRemoveViewButtons {
    display: flex;
    margin-top: $base-whitespace * 2;
  }
  
  .addRemoveViewButtons > button{
    color: $primary-color;
  }
/* ************** p.a inputs ********************** */
.lineFormatTrades {
    margin-top: $base-whitespace;
    display:grid;
    grid-template-columns: 90px 200px 200px 200px 200px;
    grid-column-gap:12px;
& > div {
    & > div {
        height: 55px;
        width: 200px;
    }
}  
}

