@import '../core/variables.scss';

// small text in case user is not loggedin
.text-viewer{
  font-size: $base-font-rem;
  font-weight: normal;
  display: block;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  color: $gray-7;
  margin-bottom: $base-whitespace;
  margin-top: $base-whitespace;
  font-size: $base-font-rem * 0.875;
  @media screen and (min-width: $breakpoint-tablet) {
    font-size: $base-font-rem * 1.125;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}

.search-Bar-viewer {
  display: block;
  margin-left: auto;
  margin-right: auto;
  border: $outline-input-color 1px solid;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: $base-whitespace * 2.75;
  outline: 1px $primary-color;
  padding: $base-whitespace $base-whitespace * 0.875;
  @media screen and (min-width: $breakpoint-tablet) {
    width: 300px;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    width: 400px;
  }
}
/* ------------------ LINECHART ---------------- */
.inputFinancialLayout {
  display: flex;
  flex-direction: column;
  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    grid-template-columns: 70% 30%;
    margin: $base-whitespace * 2 0 $base-whitespace * 2 0;
  }
}

.addDataViewer {
  font-size: $base-font-rem * 0.875;
  letter-spacing: 1px;
  list-style-type: none;
  display: flex;
  flex-direction: column;
  gap: $base-whitespace;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.125;
  }
}

.disclaimerPrices {
  font-size: $base-font-rem * 0.75;
  margin-left: $base-whitespace * 1.5;
  text-align: left;
  font-weight: 400;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem;
  }
}

.viewerGraphHeader {
  font-size: $base-font-rem;
  letter-spacing: 1px;
  font-weight: 400;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.125;
    margin-left: $base-whitespace;
  }
}
/**/
.financialData-table {
  margin-left: auto;
  margin-right: auto;
  background-color: white;
  @media screen and (min-width: $breakpoint-desktop) {
    width: 1000px;
    box-shadow: 5px 6px 16px rgba(0, 0, 0, 0.15);
  }
  &__thead {
    background-color: rgba($primary-color-lightest, 0.6);
    &-trow-theader {
      font-size: $base-font-rem * 0.875;
      padding: 10px;
      @media screen and (min-width: $breakpoint-tablet) {
        font-size: $base-font-rem;
      }
      @media screen and (min-width: $breakpoint-desktop) {
        font-size: $base-font-rem * 1.25;
      }
    }
  }
  &__tbody {
    &-trow {
      cursor: pointer;
      &-tdata {
        border-bottom: 1px solid $primary-color-lightest;
        border-top: 1px solid $primary-color-lightest;
        font-size: $base-font-rem * 0.875;
        padding: 8px;
        text-align: center;
        @media screen and (min-width: $breakpoint-tablet) {
          font-size: $base-font-rem;
          padding: 10px;
        }
        &:last-child {
          font-weight: 500;
        }
        @media screen and (min-width: $breakpoint-desktop) {
          font-size: $base-font-rem * 1.25;
        }
      }
    }
  }
}

.additional-data {
  display: flex;
  justify-content: center;
}
//pagination
.pagination {
  width: 350px;
  display: flex;
  flex-direction: column;
  margin: auto;
  @media screen and (min-width: $breakpoint-tablet) {
    width: 650px;
    align-items: center;
  }
}

.css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
  display: flex;
  flex-direction: row;
  @media screen and (min-width: $breakpoint-tablet) {
    flex-direction: row;
    padding-left: 0 !important;
    padding-right: 0;
  }
}

.MuiTablePagination-selectLabel,
.MuiInputBase-root,
.MuiTablePagination-displayedRows {
  font-family: 'Roboto', sans-serif;
}

.MuiTablePagination-displayedRows,
.MuiTablePagination-selectLabel {
  font-size: 12px;
  @media screen and (min-width: $breakpoint-tablet) {
    font-size: $base-font-rem * 1.125;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}

.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input {
  display: flex;
  align-items: center;
  font-size: 12px;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}

.css-zylse7-MuiButtonBase-root-MuiIconButton-root {
  padding: 0;
}

#root
  > main
  > div.MuiTablePagination-root.pagination.css-jtlhu6-MuiTablePagination-root
  > div
  > div.MuiInputBase-root.MuiInputBase-colorPrimary.css-16c50h-MuiInputBase-root-MuiTablePagination-select {
  padding-right: 10px;
}
//loading circle
.loading-stock-viewer > .css-18lrjg1-MuiCircularProgress-root, .css-z01bqi {
  color: $primary-color;
  left: 43%;
  margin-bottom: 300px;
  position: absolute;
  top: 17vh;
  @media screen and (min-width: $breakpoint-tablet) {
    left: 45%;
    top: 14vh;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    left: 50%;
    top: 24vh;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    left: 49%;
    top: 16vh;
  }
}