/* * {
     font-family: "Roboto", sans-serif;
     font-weight: 300;
     text-rendering: optimizeLegibility;
     -webkit-font-smoothing: antialiased;
}
 */
/* body{
     height: 100vh;
     background-color: #fafafa;
}
 */
/* .MuiButton-root {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}
.MuiSvgIcon-root {
  font-size: 2rem;
}
/* .MuiFormLabel-root {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
} */
/* .MuiInputBase-input {
  font-family: 'Roboto', sans-serif;
  font-size: 1.5rem;
  width: 320px;
} */
/* pagination input selection registries */
/* .MuiTablePagination-select {
  width: 0px;
  font-size: 0.875rem;
} */
/* calendar days */
/* .MuiPickersDay-root {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
} */
/* calendar month and year */
/* .PrivatePickersFadeTransitionGroup-root {
  font-size: 24px;
} */
/* days of the week: */
/* .MuiTypography-caption {
  font-size: 24px;
  display: flex;
  padding: 0;
  padding-left: 8px;
  font-weight: 400;
  justify-content: space-between; 
} */
/* dropdowns from the CRUD forms */
/* .MuiOutlinedInput-input {
  font-family: 'Roboto', sans-serif;
  font-size: 24px;
  font-weight: 300;
} */
/* .drawerMain{
     background-color: #7048e8;
} */
/* label borders */
/* .MuiFormLabel-root.Mui-focused {
  color: #7048e8 !important;
} */
/* .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #7048e8 !important;
} */
/* SideBar */
/* .css-12i7wg6-MuiPaper-root-MuiDrawer-paper {
  background-color: #fafafa;
} */
/* sideBar icons:*/
/* .MuiToolbar-root .css-i4bv87-MuiSvgIcon-root {
     font-size: 2.5rem;
     margin-left: 1rem;
} */
/* positioning menu icon */
/* .css-1xp6fql-MuiButtonBase-root-MuiIconButton-root{
     margin-left: -35px;
     color: #fff 
} */
/* .drawerMain{
     background-color: #7048e8;
} */
/* label borders */
/* .MuiFormLabel-root.Mui-focused {
  color: #7048e8 !important;
}
.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #7048e8 !important;
}
/* tabs 
.MuiTab-root {
  color: #7048e8;
  font-size: 20px;
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
  color: #7048e8;
}
.css-1aquho2-MuiTabs-indicator {
  background-color: #7048e8;
}
/* checkbox 
.css-8l0d08-MuiButtonBase-root-MuiCheckbox-root.Mui-checked,
.css-8l0d08-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate {
  color: #7048e8;
} */
/* informative type of algorithm: */
/* .informativeBand {
     display:flex;
     align-items: flex-start;
     justify-content: space-between;
} */
/* .preInvestingColor{
     color:#087f5b;
}
 .postInvestingColor{
     color:#a61e4d;
} */
/* .formatTag{
     margin-top: -10px;
     font-weight: 400;
     padding: 2px;
} */
/* button types: */
/*.button {
     border-radius: 22.4167px;
     border:none;
     padding: 7px 14px;
     margin: 48px 24px;
     font-size: 1rem;
     box-sizing: border-box;
}*/
/* .locateAppBarIcons {
  margin-left: auto;
  display: flex;
  align-items: center;
} */
/* .hiUserColor {
  color: white;
  font-size: 22px;
  font-weight: 400;
  letter-spacing: 2px;
} */
/* .standard {
     background-color: #e9ecef;
}
 .standard:hover {
     background-color:#ced4da;
} 
 .create {
     background-color: #96f2d7;
}
 .create:hover {
     background-color:#63e6be;
}
 .read {
     background-color: #a5d8ff;
}
 .read:hover {
     background-color: #74c0fc;
}
 .update {
     background-color: #ffd8a8 
}
 .update:hover {
     background-color:#ffc078;
}
 .delete {
     background-color: #ffc9c9 
}
 .delete:hover {
     background-color: #ffa8a8;
}
 .button-selector-benchmark {
     color: #7048e8;
     border-radius: 8px;
     border: 2px solid #7048e8;
     padding:27px 83px 27px 83px;
     width: 325px;
     margin:0;
}
 .button-selector-rfRates {
     color: #7048e8;
     border-radius: 8px;
     border: 2px solid #7048e8;
     padding:27px 83px 27px 83px;
     width: 325px;
     margin:0;
}
 .button-selector-inputForecast {
     color: #7048e8;
     border: 2px solid #7048e8;
     border-radius: 8px;
     padding:27px 83px 27px 83px;
     width: 325px;
     margin-top: 60px;
}
 .button-selector-colorReport {
     color: #7048e8;
     border: 2px solid #7048e8;
     border-radius: 8px;
     padding:27px 83px 27px 83px;
     width: 325px;
     margin-top: 60px;
}
 .button-selector-paMethodology {
     color: #7048e8;
     border: 2px solid #7048e8;
     border-radius: 8px;
     padding:27px 83px 27px 83px;
     width: 325px;
     margin-top: 60px;
}
 .button-selector-benchmark:hover{
     background-color: #f3f0ff;
     border: 2px solid #7048e8;
     color: #7048e8;
}
 .button-selector-rfRates:hover{
     background-color: #f3f0ff;
     border: 2px solid #7048e8;
     color: #7048e8;
}
 .button-selector-inputForecast:hover{
     background-color: #f3f0ff;
     border: 2px solid #7048e8;
     color: #7048e8;
}
 .button-selector-colorReport:hover {
     background-color: #f3f0ff;
     border: 2px solid #7048e8;
     color: #7048e8;
}
 .button-selector-paMethodology:hover {
     background-color: #f3f0ff;
     border: 2px solid #7048e8;
     color: #7048e8;
} */
/* cards: */
/* .card-size {
  display: inline-block;
  margin-left: 24px;
  width: 240px;
  margin-bottom: 24px;
} */
/* .algorithm-card-size {
     margin-top: 6rem;
     display: grid;
     grid-template-columns: 320px 320px 320px 320px;
     grid-template-rows: 320px 320px 320px;
     justify-content: center;
     align-content: center;
     grid-row-gap: 64px;
     grid-column-gap: 64px;
} */
/* NOTE: to remove the automatic blue background */
input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #fff inset;
}
.input-field {
  border: #adb5bd 1px solid;
  border-radius: 4px;
  padding: 16.5px 14px;
  font-size: 1rem;
  margin-left: 24px;
}
.input-field:hover {
  border: #495057 1px solid;
}
/* dropdowns: */
.dropdown-box {
  margin: 20px 20px;
}
.dropdown-formcontrol {
  width: 240px;
}
/* .search-Bar-viewer{
     border: #adb5bd 1px solid;
     border-radius: 4px;
     padding: 16.5px 14px;
     font-size: 1rem;
     width: 1200px;
     margin-left: 575px;
     margin-bottom: 46px;
} */
.input-field:hover {
  border: #495057 1px solid;
}
/* dropdowns: */
.dropdown-box {
  margin: 20px 20px;
}
.dropdown-formcontrol {
  width: 240px;
}
/* sidebar: */
/* sidebar: */
/* .navigations{
     text-decoration: none;
} */
.MuiTypography-body1 {
  font-family: 'Roboto', sans-serif;
  font-size: 20px;
  font-weight: 300;
  color: black;
  padding: 16px 12px;
}
.MuiListItemButton-root {
  padding: 16px 12px;
}
/* tables: */
.MuiTableCell-root {
  font-family: 'Roboto', sans-serif;
  border: #e9ecef 1px solid;
}
.MuiToolbar-root {
  font-family: 'Roboto', sans-serif;
}
.MuiTableCell-head {
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 2px;
}
.MuiTableCell-body {
  font-size: 20px;
  letter-spacing: 1px;
}
/* paginator: */
/* .MuiTablePagination-selectLabel {
     font-family: "Roboto", sans-serif;
}
 .MuiInputBase-root {
     font-family: "Roboto", sans-serif;
}
 .MuiTablePagination-displayedRows{
     font-family: "Roboto", sans-serif;
     font-size: 20px;
}
 .MuiTablePagination-selectLabel{
     font-size: 20px;
}
 .css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input.css-194a1fa-MuiSelect-select-MuiInputBase-input{
     min-width: 45px;
     display: flex;
     align-items: center;
     font-size: 20px;
}
 .css-pqjvzy-MuiSvgIcon-root-MuiSelect-icon{
     margin-left: 90px;
} */
/* .css-jtlhu6-MuiTablePagination-root:last-child{
     margin-left: 575px;
} */
/* texts: */
/* .headers {
     font-size: 32px;
     margin-top: 32px;
     margin-bottom: 4px;
     font-weight: 400;
} */
/* .headers:first-of-type {
  margin-top: 0px;
} */
/* .descriptions {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 24px;
  max-width: 125ch;
  line-height: 1.7;
} */
/* algorithm-icon: */
/* .iconTest {
     color: #7048e8;
     margin: 32px 0 0 16px;
     height: 48px;
     width: auto;
} */
/* algorithm-name: */
/* .algorithmName{
     font-size: 30px;
     font-weight: 500;
     margin: 0 0 4px 16px;
} */
/* algorithm-description: */
/* .algorithmDescription{
     font-size: 18px;
     font-weight: 300;
     margin: 0 0 4px 16px;
     max-width: 75ch;
     line-height: 1.5;
     height: 75px;
} */
/* algorithm-button */
/* .algorithm-card-size Button {
     margin-top: 24px;
     margin-bottom: 24px ;
} */
/* list items */
/* .listUl {
  grid-auto-flow: row;
  padding-top: 0;
  width: 100%;
} */
/* .listLi {
  display: flex;
} */
/* algorithm display: */
/* .inputDisplay {
  display: grid;
  grid-template-columns: 20% 80%;
} */
/* date dropdown: */
.dateDropdown {
  display: inline-block;
  margin: 24px 48px 0 48px;
}
/* .stockListDisplay {
  display: flex;
} */
.displayList {
  display: grid;
  grid-template-columns: auto auto;
}
.searchBar {
  display: flex;
  width: 100%;
}
/* .markSelected {
  background-color: #7048e8;
  color: #fff;
} */
/* dynamic modal: */
.dotwrapper {
  display: flex;
  align-items: flex-end;
}
.loading {
  margin: -14px 10px;
  font-size: 30px;
  align-self: baseline;
}
.dot0 {
  background-color: #000;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 0 5px;
  animation: BounceAnimation 0.5s linear infinite;
  animation-delay: 0;
}
.dot1 {
  background-color: #000;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 0 5px;
  animation: BounceAnimation 0.5s linear infinite;
  animation-delay: 0.1s;
}
.dot2 {
  background-color: #000;
  border-radius: 50%;
  width: 4px;
  height: 4px;
  margin: 0 5px;
  animation: BounceAnimation 0.5s linear infinite;
  animation-delay: 0.2s;
}
@keyframes BounceAnimation {
  0% {
    margin-bottom: 0;
  }
  50% {
    margin-bottom: 4px;
  }
  100% {
    margin-bottom: 0;
  }
}
.fade-enter {
  opacity: 0;
}
.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms cubic-bezier(0.55, 0.085, 0.68, 0.53);
}
.fade-exit {
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
/* fundamental output: */
/* .conclusions {
  max-width: 100ch;
  line-height: 1.75;
  margin-top: 24px;
  margin-bottom: 24px;
} */
/* .displayFundOutput {
  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;
  align-content: space-around;
} */
/* .displayAnalystOutput {
  display: grid;
  grid-gap: 64px;
  grid-template-columns: auto auto;
  justify-content: space-around;
} */
/* .buttonSelect {
  font-size: 24px;
  display: flex;
  font-weight: 400;
  justify-content: center;
} */
.flagButtonSelect {
  margin-left: 12px;
}
/* .multiplebuttonDistribution {
  display: grid;
  grid-template-columns: auto auto auto;
  justify-items: center;
  grid-gap: 64px;
} */
/* .twobuttonDistribution {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: center;
  grid-gap: 200px;
} */
/* .oneTableOuput {
  height: 100vh;
  margin: 60px;
} */
/* cash input field */
/* .cashInput {
  display: grid;
  justify-items: center;
  grid-row-gap: 20px;
  font-size: 24px;
} */
.numericInputField {
  width: 240px;
  font-size: 24px;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.displayOptOutput {
  display: grid;
  grid-template-columns: auto auto;
  justify-items: center;
  justify-content: space-around;
}
/* .sepOptOutputTxt {
  margin-top: 32px;
  margin-bottom: 32px;
  max-width: 75ch;
  line-height: 1.75;
} */
/* .varOuputHeader {
  grid-column-start: span 3;
} */
.labelsRecipData {
  margin-top: 20px;
}
.fieldRecipData {
  width: 320px;
}
.stnOutputFmt {
  margin-left: 60px;
}
/* .displaySTNOutput {
  display: grid;
  grid-gap: 64px;
  grid-template-columns: auto auto auto;
} */
/* .addRemoveViewButtons {
  display: flex;
  margin-top: 32px;
} */
.lineFormat {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 90px 90px 300px 120px 300px;
  /* grid-template-rows: 1fr 1fr;
     */
  grid-column-gap: 45px;
}
.lineFormatHeaders {
  display: grid;
  grid-template-columns: 90px 90px 300px 120px 300px;
  grid-column-gap: 45px;
  font-size: 24px;
}
.viewButton {
  border: #adb5bd 1px solid;
  border-radius: 6px;
  background-color: #fff;
}
.test {
  grid-column-start: 1;
  grid-column-end: 1;
}
.switchUserView {
  margin-top: 15px;
  margin-left: 7.5px;
}
/* BL output */
.handmadeHeader {
  font-family: 'Indie Flower';
  font-size: 36px;
  color: #be5f4b;
  margin-top: -100px;
  grid-column-start: 1;
  grid-column-end: 3;
}
.handmadeNote {
  font-family: 'Indie Flower';
  font-size: 24px;
  color: #be5f4b;
  margin-top: -160px;
  margin-left: 800px;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 30ch;
}
/* glossary styles: */
/* .glossaryIcons{
     height: 64px;
     width: auto;
} */
/* .introductionAlgorithm{
     font-size: 24px;
     max-width: 120ch;
     line-height: 1.7;
} */
/* .definitionsDisplay{
     display: flex;
     align-items: baseline;
} */
/* .allDefinitionLayout{
     display:grid;
     justify-content: center;
     margin-top: 96px 
} */
.searchBarAddition {
  margin-left: 0px;
}
/* .definitionTerm{
     font-size: 24px;
     width: 25% 
} */
/* .definitions{
     font-size: 24px;
     max-width: 80ch;
     line-height: 1.7;
} */
/* .definitionIntro{
     font-size: 24px;
} */
/* releases: */
/* .allReleasesLayout{
     display:grid;
     justify-content: center;
     margin-top: 96px;
}
 */
/* .releaseWidth{
     width: 1500px;
} */
/* .marginListItems{
     margin-left: 128px;
} */
/* .technologyRelease{
     letter-spacing: 3px;
     font-size: 24px;
} */
/* .releaseTextFmts{
     font-size: 24px;
} */
/* landing */
/* .defaultLandingStyle {
     margin: 0;
     padding: 0;
     border: 0;
     font-size: 100%;
     vertical-align: baseline;
     display: flex;
     justify-content: space-around;
}
 */
/* .initialTextMargin{
     margin-right: 90px;
}
 */
/* .containerLanding {
     max-width: 1264px;
     padding-left: auto;
     padding-right: auto;
     width: 100%;
     display: inline-block;
}
 */
/* .landingButton {
     float: right;
     margin-left: 16px;
     padding: 12px 24px 10px 24px;
     text-decoration: none;
     font-size: 18px !important;
     font-weight: 400;
     border-radius: 8px;
     line-height: 1em !important;
     -webkit-transition: background 0.15s ease-in-out;
     transition: background 0.15s ease-in-out;
}
 */
/* .betaTag{
     float: left;
     margin-left: 0px;
     padding: 6px 12px 6px 12px;
     text-decoration: none;
     font-size: 18px;
     font-weight: 400;
     border-radius: 8px;
     line-height: 1em;
     -webkit-transition: background 0.15s ease-in-out;
     transition: background 0.15s ease-in-out;
     color: #7048e8;
     background: #f1f3f5;
}
 */
/* .algoUiIcon{
     line-height: 1em;
     float: left;
     padding: 6px 12px 6px 12px;
     font-weight: 400;
     font-size: 18px 
}
 */
/* .primary {
     cursor: pointer;
     background: #9775fa;
     color: #fff;
}
 */
/* .primary:hover {
     background: #7048e8;
}
 */
/* .secondary {
     color: #7048e8;
     text-decoration: none;
     background: none;
}
 .secondary:hover {
     cursor: pointer;
     background-color: #f1f3f5;
}
 */
/* .predominantButton {
     display:inline-block;
     margin-top: 40px;
     padding: 20px 40px 18px 40px;
     border-radius: 8px;
     font-weight: 400;
     font-size: 18px;
     text-decoration: none;
     -webkit-transition: background 0.15s ease-in-out;
     transition: background 0.15s ease-in-out;
}
 */
/* .predominantButton:hover {
     cursor: pointer;
}
 */
/* .predominantHeader {
     font-size: 36px;
     line-height: 1.1;
     margin: .67em 0;
     font-weight: 500;
     margin-top: 20px;
     margin-bottom: 10px;
     margin: 0;
     padding: 0;
     border: 0;
     font-size: 48px !important;
     vertical-align: baseline;
     font-style: normal;
     font-weight: normal;
     line-height: 58px !important;
     color: #212529;
     margin-bottom: 24px !important;
}
 */
/* .subHeader {
     color: #746E8C;
     font-weight: normal !important;
     font-size: 16px !important;
     line-height: 28px !important;
     margin: 0 0 10px;
}
 */
/* .item {
     display: table-cell;
     overflow: visible;
     vertical-align: middle;
     line-height: 1.7em;
     position: relative;
}
 */
/* .smallerHeaders {
     line-height: 1.1;
     font-weight: 400;
     margin-top: 20px;
     padding: 0;
     border: 0;
     font-size: 24px !important;
     font: inherit;
     vertical-align: baseline;
     font-style: normal;
     font-weight: normal;
     color: #212529;
     margin-bottom: 16px;
     margin-top: 16px;
}
 */
/* p.defaultLandingStyle {
     margin: 0;
     padding: 0;
     border: 0;
     font-size: 20px !important;
     vertical-align: baseline;
     line-height: 1.8em !important;
     padding-right: 80px;
}
 */
/* .flexHeader{
     margin-top: 90px;
     display: flex;
     align-items: center;
     justify-content: start;
}
 */
/* .flexSubHeader{
     margin-top: 120px;
     margin-left: 100px;
     display: flex;
     align-items: baseline;
}
 */
/* .itemExplanation{
     max-width: 28ch;
}
 */
/* .item1IconColor{
     color: #087f5b 
}
 .item2IconColor{
     color: #a61e4d 
}
 .item3IconColor{
     color: #7048e8 
}
 */
/* .rectangle{
     margin-top: 120px;
     width:1240px;
     height:320px;
     border-radius: 8px;
     background:#7048e8;
     opacity: 0.85;
}
 */
/* .howWorksColorIcon{
     color: white;
}
 */
/* .headerHowDoesWork{
     text-align:center;
     padding: 24px;
     margin-bottom: 8px;
     color: white;
     font-weight: 400;
     font-size: 24px;
}
 */
/* .explanationHowDoesWork{
     text-align:center;
     margin-top: -12px;
     max-width: 30ch;
     color: white;
     font-weight: 400;
     font-size: 20px;
}
 */
/* .displayHowToUse{
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     grid-template-rows: 1fr 1fr;
     position: relative;
     justify-items: center;
     align-items: start;
}
 */
/* .frecuentAskedQFmt{
     margin-top: 120px;
     display: flex;
     flex-direction: column;
     gap: 5rem;
     align-items: center;
}
 */
/* .headerAskedQSpacing{
     display: flex;
     flex-direction: column;
     gap: 3rem;
}
 */
/* .mainHeaderFreqAskedQ {
     margin: 0;
     font-size: 2rem;
     font-weight: 400;
     letter-spacing: -.025em;
     line-height: 1 
}
 */
/* .subHeaderFreqAskedQ {
     font-weight: 400;
     font-size: 1.125rem;
     line-height: 1.75rem;
     color: #746E8C;
     --tw-text-opacity: 1;
     color: rgb(74 81 104/var(--tw-text-opacity));
}
 */
/* .reachUsFmt {
     color: #7048e8;
     font-weight: 400;
     display: inline-flex;
     flex: 1 1 auto;
     align-items: center;
     justify-content: center;
     font-size: 1rem;
     line-height: 1.625rem;
     -moz-column-gap: .75rem;
     column-gap: .75rem;
     text-decoration: none;
}
 */
/* .reachUsFmt:hover {
     text-decoration-line: underline;
     transition-duration: .2s;
}
 */
/* .questionsLayout{
     display: grid;
     grid-template-columns: repeat(2,minmax(0,1fr));
     column-gap: 6rem;
     row-gap: 2rem;
}
 */
/* .questionSpacing{
     margin-top: .5rem;
     margin-bottom: calc(1rem*var(--tw-space-y-reverse));
     margin-top: calc(1rem*(1 - var(--tw-space-y-reverse)));
}
 */
/* .questionHeader{
     font-size: 1.125rem;
     font-weight: 600;
     line-height: 1.75rem;
     text-align: left;
     --tw-text-opacity: 1;
     color: rgb(18 25 51/var(--tw-text-opacity));
}
 */
/* .responseBody{
     padding-bottom: 1rem;
     font-weight: 400;
     font-size: 1rem;
     line-height: 1.625rem;
     max-width: 60ch;
     text-align: left;
     --tw-text-opacity: 1;
     color: rgb(34 42 69/var(--tw-text-opacity));
}
 */
/* .finalHeaderSpacing{
     padding: 120px 0px 320px 0px;
     display: flex;
     justify-content: center;
}
 */
/* .finalHeaderLayout{
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
}
 */
/* .lastButtonMargin{
     margin-left: -80px;
}
 */
/* login / signUp */
/* .IconLogInColor{
     color: #7048e8 
}
 */
/* .logInGoBack{
     text-transform: capitalize;
     color: inherit;
     font-weight: inherit;
}
 */
/* .logInBox{
     margin: 128px auto auto auto;
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     width:420px;
}
 */
/* .redirectLogin{
     color: #7048e8;
     text-decoration: none;
     font-size: 16px;
}
 .redirectLogin:hover{
     text-decoration: underline;
}
 */
/* .alert{
     display: flex;
     align-items: center;
}
 */
/* .menu {
  position: relative;
  display: inline-block;
} */

/* .menu-button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #845ef7;
} */

/* .menu-items {
  width: 150px;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: none;
} */

/* .menu-items li {
  padding: 10px;
}

.menu-items li a {
  color: #333333;
  text-decoration: none;
}

.menu.open .menu-items {
  display: block;
}

.menuOptionsPa {
  color: black;
} */

/* .avatarMenu{
     margin-top: 48px;
     margin-left: auto;
} */
/* .avatarMenuItem{
     font-size: 14px;
     padding: 4px 12px;
} */
/* .restorePwdComment{
     font-size: 16px;
     max-width: 80ch;
     line-height: 1.7;
     text-align:center;
}
 */
/* .confirmationRestoredPwd{
     font-size: 30px;
     line-height: 1.7;
     max-width: 40ch;
     text-align:center;
}
 */
.circularProgress {
  color: #fff;
}
/* Me endpoint */
/* .settingsHeader {
     font-size: 52px;
     margin-bottom: 64px;
     font-weight: 400;
     color: #7048e8;
}
 */
/* .profileLabel {
     color: #7048e8;
     font-size: 30px;
     margin: 24px 0;
     font-weight: 400;
}
 */
.inputLabel {
  margin-bottom: 48px;
  width: 560px;
}
/* .saveSettngsButton{
     margin-top: 48px;
     margin-bottom: 24px;
}
 */
/* .posbut{
     display: flex;
     justify-content: flex-end;
     margin-bottom: 80px;
}
 */
/* .deleteButton {
     background: #fa5252;
     color: #fff;
}
 .deleteButton:hover {
     background: #c92a2a;
     cursor: pointer;
}
 */
/* .buttonsLayout {
     display: grid;
     justify-content: end;
}
 */
/* .descriptionDelete {
     color: #495057;
     font-weight: 400;
     font-size: 18px;
}
 */
.outstandWord {
  font-weight: 400;
}
.favButton {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 40px;
}
.favButtonColor {
  font-size: 18px;
  border-radius: 8px;
  border: 2px solid #0b7285;
  color: #0b7285;
  font-weight: 700;
}
.favButtonColor:hover {
  border: 2px solid #0b7285;
  font-weight: 700;
  background-color: #e3fafc;
}
.avatarBackGroundColor {
  background-color: #f8f0fc;
}
/* .savedAlgorithmsDisplay{
     display: grid;
     grid-template-columns: 15% 85%;
} */
/* .lineFormatTrades {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 90px 250px 250px 250px 250px;
  /* grid-template-rows: 1fr 1fr;
     *
  grid-column-gap: 45px;
} */
/* switch color
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
  color: #9775fa;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
  background-color: #9775fa;
}
.css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked.Mui-disabled {
  color: #9775fa;
} */
/* .paRows {
  background-color: #f8f9fa;
  font-size: 18px;
} */
.paAssetTypeSector {
  color: #5c940d;
}
.paAssetTypeIndustry {
  color: #d9480f;
}
.paAssetTypeStocks {
  color: #5f3dc4;
}
.paAssetTypeOther {
  color: #212529;
}
.paRowsCash {
  background-color: #f8f9fa;
  font-size: 18px;
}
/* .paPpalColumn {
  /* color: #212529;
     *
  background-color: #f1f3f5;
  border: 1px solid #adb5bd;
  font-weight: 700;
  letter-spacing: 1px;
  font-size: 18px;
} */
/* .negativeContribution {
  color: #a61e4d;
  font-weight: 700;
  font-size: 18px;
}
.positiveContribution {
  color: #087f5b;
  font-weight: 700;
  font-size: 18px;
} */
/* .headerTablePa {
  color: #f8f9fa;
  background-color: #845ef7;
  border: 0.5px solid #f3f0ff;
  font-weight: 700;
  font-size: 18px;
} */
/* .defaultPaFontSize {
  font-size: 18px;
} */
/* .filterIcon {
  color: #f8f9fa;
  background-color: #845ef7;
} */
/* .handmadeNotePA {
  font-family: 'Indie Flower';
  font-size: 24px;
  color: #be5f4b;
  margin-left: 800px;
  grid-column-start: 1;
  grid-column-end: 3;
  max-width: 30ch;
  font-weight: 700;
} */
/* .subHeadersPA {
  letter-spacing: 2px;
  font-size: 24px;
  font-weight: 500;
  color: #495057;
} */
/* .headerPA {
  letter-spacing: 1px;
  font-size: 32px;
  color: #212529;
} */
/* .conclusionsPA {
  max-width: 100ch;
  line-height: 1.75;
  margin-top: 24px;
  margin-bottom: 24px;
  color: #495057;
  font-weight: 400;
} */
/* .bulletPointsPA {
  max-width: 100ch;
  line-height: 1.75;
  color: #495057;
  font-weight: 400;
} */
/* .tooltipSize{
     font-size: 20px;
} */
/* .tooltipSize:hover{
     cursor: pointer;
} */
/* help section */
/* .helpButton:hover{
     cursor: pointer;
} */
/* .listHelpSection{
     display: grid;
     grid-template-columns: 1fr 2fr;
} */
/* .FaqTitle {
     margin-left: 24px;
     font-size: 32px;
     font-weight: 500;
     letter-spacing: 1px;
     color: #5f3dc4;
} */
/* .FaqHeader {
     margin-top: 24px;
     font-size: 24px;
     max-width: 60ch;
     font-weight: 500;
     color: #5f3dc4;
} */
/* .FaqDesc {
     margin: 32px;
     font-size: 22px;
     max-width: 60ch;
     line-height: 1.7;
     color: #495057;
     font-weight: 400;
}
 .FaqSubHeader {
     margin: 16px;
     font-size: 22px;
     color: #495057;
     font-weight: 500;
} */
/* .FAQAnswerSectionLayout{
     margin: 24px;
     grid-column-start: 2;
     grid-column-end: 3;
} */
.FaqLayoutHeight {
  height: 800px;
}
/* .stickyListHelpSection{
     position: fixed;
     grid-column-start: 1;
     grid-column-end: 2;
} */
/* page not found */
/* .notFoundContainer {
     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;
     height: 80vh;
}
 .h1Container {
     font-size: 10rem;
     margin-bottom: 2rem;
     color: #495057;
     font-weight: 400;
}
 .pContainer {
     font-size: 2rem;
     margin-bottom: 2rem;
     color: #868e96;
} */
/* .aContainer {
     margin-top: 3rem;
     background-color: #845ef7;
     color: #fff;
     padding: 1rem 2rem;
     text-decoration: none;
     border-radius: 5px;
     transition: background-color 0.3s ease;
} */
/* .aContainer:hover {
     background-color: #7048e8;
} */
/* stock viewer */
/* .inputFinancialLayout {
     /* MOVIL* display: flex;
     flex-direction: column; 
      display: grid;
     grid-template-columns: auto auto; 
     margin: 64px 0 64px 0;
} */
/* .viewerGraphHeader{
     font-size: 24px;
     margin: 24px 24px;
     letter-spacing: 1px;
     font-weight: 400;
} */
/* .addDataViewer{
     font-size: 20px;
     letter-spacing: 1px;
     list-style-type: none;
     display: flex;
     flex-direction: column;
     gap: 36px;
} */
/* .flags {
     font-size: 24px;
     margin-top: -12px;
     margin-left: 0;
     padding-right:0;
     padding-left:0;
}
 */
/* .helpSectionButtons{
    max-width: 40ch;
  } */

/* .explanatoryRelease{
    font-size: 32px;
    max-width: 120ch;
    margin: auto;
    margin-bottom: 90px;
  } */
/* .lineFormatViews {
  margin-top: 16px;
  display: grid;
  grid-template-columns: 90px 90px 300px 120px 300px;
  /* grid-template-rows: 1fr 1fr; *
  grid-column-gap: 45px;
} */
/* .disclaimerPrices{
     font-size: 12px;
     margin-left: 24px;
     text-align: left;
     font-weight: 400;
} */
/* .css-6od3lo-MuiChip-label {
     font-weight: 500;
} */

/* .chipTokens{
     color: #7048e8;
     font-size: 22px;
     margin-left: 20px;
     letter-spacing: 0.5px;
     border: 2px solid #ffe066;
     background-color: #ffe066;
} */

/* .tokensAlgorithms{
     color: #e67700;
     font-size: 18px;
     border: 2px solid #fff9db;
     background-color: #fff9db;
     position: relative;
     bottom: 200px;
     left: 0;
} */


/* Styles for the loading page */
.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.loading-spinner {
  border: 6px solid transparent;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  animation: spin 1.5s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}