@import '../core/variables.scss';

/* div de los iconos de cada icono de las explicaciones de los algoritmos */
.css-16lloyr-MuiBottomNavigation-root {
  grid-row-start: 1;
  background-color: $background-color;
  margin-top: $base-whitespace * 3;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: $base-whitespace * 1.5;
  @media screen and (min-width: $breakpoint-tablet) {
    grid-template-columns: repeat(5, 1fr);
    margin: $base-whitespace * 3 $base-whitespace * 1.5 0 $base-whitespace * 1.5;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    grid-template-columns: repeat(9, 1fr);
    margin-right: $base-whitespace * 10;
    margin-left: $base-whitespace * 5;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-left: 0;
  }
}

.glossaryIcons {
  height: 64px;
  width: auto;
}

.allDefinitionLayout {
  margin-top: $base-whitespace * 18;
  @media screen and (min-width: $breakpoint-tablet) {
    margin: $base-whitespace * 11 $base-whitespace 0 $base-whitespace;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    margin: $base-whitespace * 5 $base-whitespace * 9 0 $base-whitespace * 6;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-right: $base-whitespace;
  }
}

.definitionIntro {
  font-size: $base-font-rem * 1.5;
}

.introductionAlgorithm {
  text-align: justify;
  text-justify: inter-word;
  font-size: $base-font-rem * 1.125;
  line-height: 1.7;
  @media screen and (min-width: $breakpoint-tablet) {
    max-width: 65ch;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    max-width: 120ch;
    font-size: $base-font-rem * 1.25;
    margin-right: $base-whitespace * 6;
  }
  @media screen and (min-width: $breakpoint-largeDesktop) {
    margin-right: $base-whitespace;
  }
}

/*definitions display*/
.definitionsDisplay {
  @media screen and (min-width: $breakpoint-desktop) {
    display: grid;
    grid-template-columns: 20% 80%;
    align-items: baseline;
  }
}

.definitionTerm {
  font-weight: 500;
  font-size: $base-font-rem * 1.25;
  margin-bottom: 0px;
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.5;
    margin-right: calc($base-whitespace / 2);
  }
}

.definitions {
  margin-top: $base-whitespace;
  font-size: $base-font-rem * 1.125;
  text-align: justify;
  text-justify: inter-word;
  line-height: 1.7;
  @media screen and (min-width: $breakpoint-tablet) {
    max-width: 65ch;
  }
  @media screen and (min-width: $breakpoint-desktop) {
    font-size: $base-font-rem * 1.25;
  }
}
